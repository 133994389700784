import styles from "./Card.module.scss";
import { CardInfo } from "components/Team/Infos";

const Card = ({ image, title, social }: CardInfo) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.profile} src={image} alt="profile"></img>
      <div className={styles.title}>{title}</div>
      <div className={styles.socials}>
        {social.url.map((infos: string, index: number) => {
          return (
            <div className={styles.social} key={index}>
              {
                <a href={infos} target={"_blank"} key={index} rel="noreferrer">
                  <img src={social.image[index]} alt="social"></img>
                </a>
              }
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { Card };
