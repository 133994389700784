import styles from "./Footer.module.scss";
import { RefObject } from "react";
// import { useMediaQuery } from "react-responsive";
import { Logo, Twitter, Discord, Medium, Github, Mail } from "assets";

const Footer = ({ joinUsRef }: { joinUsRef: RefObject<HTMLDivElement> }) => {
  // const mobile = useMediaQuery({
  //   query: "(max-width: 400px)",
  // });

  return (
    <div className={styles.wrapper} ref={joinUsRef}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <a href="/">
            <Logo />
          </a>
        </div>
        <ul className={styles.links}>
          <li>
            <a
              href="https://docs.fibrous.finance/faq"
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
          </li>
          {/* <li>
            <a
              href="https://docs.fibrous.finance/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li> */}
          {/* <li>
            <a
              href="https://docs.fibrous.finance/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </li> */}
          <li>
            <a
              href="https://docs.fibrous.finance"
              target="_blank"
              rel="noreferrer"
            >
              Docs
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLsxLve21ZBFrqQoCVL-gOASaDEmumoPRV"
              target="_blank"
              rel="noreferrer"
            >
              Tutorial
            </a>
          </li>
        </ul>
        <ul className={styles.social}>
          <li>
            <a
              href="https://twitter.com/FibrousFinance"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Fibrous-Finance"
              target="_blank"
              rel="noreferrer"
            >
              <Github />
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@fibrous"
              target="_blank"
              rel="noreferrer"
            >
              <Medium />
            </a>
          </li>
          <li>
            <a
              href="https://discord.gg/fibrous"
              target="_blank"
              rel="noreferrer"
            >
              <Discord />
            </a>
          </li>
          <li>
            <a
              href="mailto:support@fibrous.finance"
              target="_blank"
              rel="noreferrer"
            >
              <Mail />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export { Footer };
