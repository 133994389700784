import styles from "./Intro.module.scss";
import FIBROUS from "assets/fibrous-logo.png";
import INTRO from "assets/intro.png";
import { RefObject } from "react";
import DOOR from "assets/login.png";
const Intro = ({ introRef }: { introRef: RefObject<HTMLDivElement> }) => {
  return (
    <div className={styles.wrapper} ref={introRef}>
      <div className={styles.navbar}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={FIBROUS} alt="logo"></img>
          </div>
          <div className={styles.launch}>
            <a
              target={"_blank"}
              href="https://app.fibrous.finance"
              rel="noreferrer"
            >
              <div className={styles.text}>Launch App</div>
              <img src={DOOR} alt="doorLogo"></img>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.image}>
        <img src={INTRO} alt="logo"></img>
      </div>
      <div className={styles.infos}>
        <div className={styles.title}>ALL AMMS IN ONE PLACE</div>
        <div className={styles.text}>
          Fibrous is price exploration and routing algorithm across multiple
          AMMs that offers token exchanges with best and cheapest rate at
          Starknet Validity Rollup.
          <br></br>
          <br></br>
          Fibrous finds the most efficient paths for a token swap, also splits
          your funds between different protocols and aggregates best rates
          across Starknet AMMs.
        </div>
      </div>
    </div>
  );
};
export { Intro };
