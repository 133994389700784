import styles from "./Team.module.scss";
import { Card } from "./Card/Card";
import { INFOS } from "./Infos";
import { RefObject, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { clsnm } from "utils/clsnm";

const Team = ({ teamRef }: { teamRef: RefObject<HTMLDivElement> }) => {
  const isPC = useMediaQuery({ query: "(min-width: 1201px)" });
  const isTablet = useMediaQuery({
    query: "(max-width: 1200px) and (min-width: 626px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 625px)" });

  const getCardsPerPage = () => {
    if (isMobile) return 2;
    if (isTablet) return 4;
    return 8;
  };

  const [cardsPerPage, setCardsPerPage] = useState(getCardsPerPage());
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(INFOS.length / cardsPerPage);

  useEffect(() => {
    setCardsPerPage(getCardsPerPage());
    setCurrentPage(0);
  }, [isPC, isTablet, isMobile]);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) =>
      prevPage > 0 ? prevPage - 1 : totalPages - 1
    );
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * cardsPerPage;
    return INFOS.slice(startIndex, startIndex + cardsPerPage);
  };

  return (
    <div className={styles.wrapper} ref={teamRef}>
      <div className={styles.title}>OUR TEAM</div>
      <div className={styles.row}>
        {getPaginatedData().map((data, i) => (
          <Card
            key={i}
            image={data.image}
            title={data.title}
            social={data.social}
          />
        ))}
      </div>
      <div className={styles.slicer}>
        <div className={styles.back} onClick={goToPreviousPage}>
          <MdOutlineNavigateBefore size={32} />
        </div>
        {Array.from({ length: totalPages }, (_, i) => (
          <div
            key={i}
            className={
              currentPage === i
                ? clsnm(styles.bumble, styles.on)
                : styles.bumble
            }
          />
        ))}
        <div className={styles.next} onClick={goToNextPage}>
          <MdOutlineNavigateNext size={32} />
        </div>
      </div>
    </div>
  );
};

export { Team };
