import styles from "./LookingFor.module.scss";
import { RefObject } from "react";
import ANIMATION from "assets/animation.gif";

const LookingFor = ({
  lookingForRef,
}: {
  lookingForRef: RefObject<HTMLDivElement>;
}) => {
  return (
    <div className={styles.wrapper} ref={lookingForRef}>
      <div className={styles.titles}>
        <div className={styles.title}>
          <div className={styles.title1}>LOOKING</div>
          <div className={styles.title2}>FOR</div>
          <div className={styles.title3}>BEST</div>
          <div className={styles.title4}>SWAP</div>
          <div className={styles.title5}>RATES?</div>
        </div>

        <img className={styles.animation} src={ANIMATION} alt="logo"></img>
      </div>
      <div className={styles.infos}>
        Welcome to Fibrous an AMM aggregator built on Starknet! Our app makes it
        easy to find the best swap rates across all Starknet AMMs. With Fibrous,
        you can access the most competitive prices. Our user-friendly interface
        is designed to make trading on Starknet more efficient and accessible
        for everyone. Try it out, and experience the power of decentralized
        finance on the most secure and scalable Rollup on Ethereum!
      </div>

      <div className={styles.buttons}>
        <div className={styles.button}>
          <a
            target={"_blank"}
            href="https://app.fibrous.finance/"
            rel="noreferrer"
          >
            Start Swap
          </a>
        </div>
      </div>
    </div>
  );
};
export { LookingFor };
