import React, { useRef, RefObject, Fragment } from "react";
import { Helmet } from "react-helmet";
import styles from "./Landing.module.scss";
import { Intro, LookingFor, Team, Route, Footer } from "components";
import { useMediaQuery } from "react-responsive";

const Landing = () => {
  const isDesktopOrPhone = useMediaQuery({
    query: "(max-width: 1200px)",
  });

  const introRef = useRef<HTMLDivElement>(null);
  const lookingForRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const joinUsRef = useRef<HTMLDivElement>(null);

  const handleScroll = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current === null) {
      return;
    }

    window.scrollTo({
      top: ref.current?.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Fibrous - The First Aggregator on Starknet</title>
        <meta
          name="description"
          content="Fibrous represents an advanced price exploration and routing mechanism spanning multiple AMMs, meticulously designed to facilitate token swaps at the most optimal and cost-effective rate within Starknet validity Rollup."
        />
        <meta
          name="keywords"
          content="Starknet, aggregator, swap, Fibrous, Fibrous Finance,Starknet aggregator, Starknet swap, Starknet AMM, Starknet AMM aggregator, Starknet AMM swap"
        />
        <link rel="canonical" href="https://fibrous.finance/" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Fibrous - The First Aggregator on Starknet"
        />
        <meta
          property="og:description"
          content="Fibrous represents an advanced price exploration and routing mechanism spanning multiple AMMs, meticulously designed to facilitate token swaps at the most optimal and cost-effective rate within Starknet validity Rollup."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fibrous.finance/" />
        <meta
          property="og:image"
          content="https://fibrous.finance/opengraph.png"
        />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Fibrous - The First Aggregator on Starknet"
        />
        <meta
          name="twitter:description"
          content="Fibrous represents an advanced price exploration and routing mechanism spanning multiple AMMs, meticulously designed to facilitate token swaps at the most optimal and cost-effective rate within Starknet validity Rollup."
        />
        <meta
          name="twitter:image"
          content="https://fibrous.finance/opengraph.png"
        />
        <meta name="twitter:url" content="https://fibrous.finance/" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Corporation",
              "name": "Fibrous Finance",
              "url": "https://fibrous.finance/",
              "logo": "https://fibrous.finance/logo.png", // replace with your actual logo URL
              "sameAs": [
                "https://discord.gg/fibrous",
                "https://medium.com/@fibrous",
                "https://github.com/Fibrous-Finance",
                "https://twitter.com/FibrousFinance"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "email": "support@fibrous.finance",
                "contactType": "Customer service"
              },
              "description": "Fibrous represents an advanced price exploration and routing mechanism spanning multiple AMMs, meticulously designed to facilitate token swaps at the most optimal and cost-effective rate within Starknet validity Rollup."
            }
          `}
        </script>
      </Helmet>

      {isDesktopOrPhone ? (
        <Fragment></Fragment>
      ) : (
        <Route
          introHandle={() => handleScroll(introRef)}
          lookingForHandle={() => handleScroll(lookingForRef)}
          teamHandle={() => handleScroll(teamRef)}
          joinUsHandle={() => handleScroll(joinUsRef)}
        />
      )}
      <Intro introRef={introRef} />
      <LookingFor lookingForRef={lookingForRef} />
      <Team teamRef={teamRef} />
      <Footer joinUsRef={joinUsRef} />
    </div>
  );
};

export { Landing };
