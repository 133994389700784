import GITHUB from "assets/github.png";
import TWITTER from "assets/twitter.png";
import TOBBY from "assets/profiles/tobbykitty.png";
import HAMZAKARABAG from "assets/profiles/hamzakarabag.png";
import ALIMSAHIN from "assets/profiles/alimsahin.png";
import ULASERDOGAN from "assets/profiles/ulaserdogan.png";
import ENES from "assets/profiles/enes.jpg";
import ARIFABI from "assets/profiles/arifabim.png";
import GULLU from "assets/profiles/gullu.jpeg";
import BEHZAT from "assets/profiles/behzat.png";
import HADO from "assets/profiles/hado.png";
import CANER from "assets/profiles/caner.png";
import ANIL from "assets/profiles/anil.png";
import DRVIR from "assets/profiles/drVir.jpeg";
import MURATCAN from "assets/profiles/muratcan.png";

export type CardInfo = {
  image: string;
  title: string;
  social: { url: string[]; image: string[] };
};

export const INFOS: CardInfo[] = [
  {
    image: TOBBY,
    title: "Team Leader",
    social: { url: ["https://twitter.com/TobbyKitty"], image: [TWITTER] },
  },
  {
    image: HAMZAKARABAG,
    title: "Developer",
    social: {
      url: ["https://twitter.com/zetsuboii_", "https://github.com/zetsuboii"],
      image: [TWITTER, GITHUB],
    },
  },
  {
    image: ALIMSAHIN,
    title: "Developer",
    social: {
      url: ["https://twitter.com/aalimsahin", "https://github.com/aalimsahin"],
      image: [TWITTER, GITHUB],
    },
  },
  {
    image: ULASERDOGAN,
    title: "Developer",
    social: {
      url: ["https://twitter.com/ulerdogan", "https://github.com/ulerdogan"],
      image: [TWITTER, GITHUB],
    },
  },
  {
    image: BEHZAT,
    title: "Developer",
    social: {
      url: ["https://twitter.com/0xKermo", "https://github.com/0xKermo"],
      image: [TWITTER, GITHUB],
    },
  },
  {
    image: ENES,
    title: "Developer",
    social: {
      url: [
        "https://twitter.com/ahmetenesdur",
        "https://github.com/ahmetenesdur",
      ],
      image: [TWITTER, GITHUB],
    },
  },
  {
    image: ARIFABI,
    title: "Art Director",
    social: { url: ["https://twitter.com/barbarussayha"], image: [TWITTER] },
  },
  {
    image: GULLU,
    title: "Art Director",
    social: { url: ["https://twitter.com/gullurydz"], image: [TWITTER] },
  },
  {
    image: HADO,
    title: "Designer",
    social: {
      url: ["https://twitter.com/0xHado"],
      image: [TWITTER],
    },
  },
  {
    image: DRVIR,
    title: "UX Researcher",
    social: {
      url: ["https://twitter.com/drvir35"],
      image: [TWITTER],
    },
  },
  {
    image: CANER,
    title: "Business Developer",
    social: {
      url: ["https://twitter.com/state_maxi"],
      image: [TWITTER],
    },
  },
  {
    image: ANIL,
    title: "Content Creator",
    social: {
      url: ["https://twitter.com/anil2ec4"],
      image: [TWITTER],
    },
  },
  {
    image: MURATCAN,
    title: "Content Creator",
    social: {
      url: ["https://twitter.com/mrtcn_eth"],
      image: [TWITTER],
    },
  },
];
