import styles from "./Route.module.scss";
import { BsChevronCompactUp, BsChevronCompactDown } from "react-icons/bs";
import { HOVER } from "types/router";
import { useState, useEffect } from "react";
import { clsnm } from "utils/clsnm";
import { ReactComponent as CONTACT } from "assets/icons/contact.svg";
import { ReactComponent as HOME } from "assets/icons/home.svg";
import { ReactComponent as PROTOCOL } from "assets/icons/protocol.svg";
import { ReactComponent as TEAM } from "assets/icons/team.svg";
import { ReactComponent as HOMEHOVER } from "assets/icons/home-hover.svg";

const Route = ({
  introHandle,
  lookingForHandle,
  teamHandle,
  joinUsHandle,
}: {
  introHandle: () => void;
  lookingForHandle: () => void;
  teamHandle: () => void;
  joinUsHandle: () => void;
}) => {
  const [page, setPage] = useState<HOVER>(HOVER.INTRO);

  useEffect(() => {
    window.addEventListener("scroll", pop);
    return () => window.removeEventListener("scroll", pop);
  }, []);

  const handleWithButton = (button: string) => {
    if (button === "up") {
      if (HOVER.JOINUS === page) {
        teamHandle();
      } else if (HOVER.TEAM === page) {
        lookingForHandle();
      } else if (HOVER.LOOKINGFOR === page) {
        introHandle();
      }
    } else if (button === "down") {
      if (HOVER.INTRO === page) {
        lookingForHandle();
      } else if (HOVER.LOOKINGFOR === page) {
        teamHandle();
      } else if (HOVER.TEAM === page) {
        joinUsHandle();
      }
    }
  };

  const pop = () => {
    if (window.innerHeight < 780) {
      if (window.scrollY < 600) {
        setPage(HOVER.INTRO);
      } else if (window.scrollY < 1550 && window.scrollY > 600) {
        setPage(HOVER.LOOKINGFOR);
      } else if (window.scrollY < 2250 && window.scrollY > 1550) {
        setPage(HOVER.TEAM);
      } else {
        setPage(HOVER.JOINUS);
      }
    } else if (window.innerHeight < 950) {
      if (window.scrollY < 600) {
        setPage(HOVER.INTRO);
      } else if (window.scrollY < 1550 && window.scrollY > 600) {
        setPage(HOVER.LOOKINGFOR);
      } else if (window.scrollY < 2250 && window.scrollY > 1550) {
        setPage(HOVER.TEAM);
      } else {
        setPage(HOVER.JOINUS);
      }
    } else {
      if (window.scrollY < window.innerHeight / 2) {
        setPage(HOVER.INTRO);
      } else if (
        window.scrollY < (window.innerHeight * 3) / 2 &&
        window.scrollY > window.innerHeight / 2
      ) {
        setPage(HOVER.LOOKINGFOR);
      } else if (
        window.scrollY < (window.innerHeight * 17) / 8 &&
        window.scrollY > (window.innerHeight * 3) / 2
      ) {
        setPage(HOVER.TEAM);
      } else {
        setPage(HOVER.JOINUS);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.upDown} onClick={() => handleWithButton("up")}>
        <BsChevronCompactUp size={16} />
      </div>
      <div
        className={
          page === HOVER.INTRO ? clsnm(styles.button, styles.on) : styles.button
        }
        onClick={introHandle}
      >
        {page === HOVER.INTRO ? (
          <HOMEHOVER className={styles.icon} />
        ) : (
          <HOME className={styles.icon} />
        )}
        {page === HOVER.INTRO && <div className={styles.name}>Fibrous</div>}
      </div>
      <div
        className={
          page === HOVER.LOOKINGFOR
            ? clsnm(styles.button, styles.on)
            : styles.button
        }
        onClick={lookingForHandle}
      >
        <PROTOCOL className={styles.icon} />
        {page === HOVER.LOOKINGFOR && (
          <div className={styles.name}>Protocol</div>
        )}
      </div>
      <div
        className={
          page === HOVER.TEAM ? clsnm(styles.button, styles.on) : styles.button
        }
        onClick={teamHandle}
      >
        <TEAM className={styles.icon} />
        {page === HOVER.TEAM && <div className={styles.name}>Team</div>}
      </div>
      <div
        className={
          page === HOVER.JOINUS
            ? clsnm(styles.button, styles.on)
            : styles.button
        }
        onClick={joinUsHandle}
      >
        <CONTACT className={styles.icon} />
        {page === HOVER.JOINUS && <div className={styles.name}>Contact</div>}
      </div>
      <div className={styles.upDown} onClick={() => handleWithButton("down")}>
        <BsChevronCompactDown size={16} />
      </div>
    </div>
  );
};
export { Route };
