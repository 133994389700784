import { useInitialTheme } from "hooks/useInitialTheme";
import { Landing } from "pages";
import { Helmet } from "react-helmet";

function App() {
  useInitialTheme();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Landing />
    </div>
  );
}

export default App;
